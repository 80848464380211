<!--
* @description 新闻详情
* @fileName newDetail.vue
* @author liulian
* @date 2023/07/14 18:15:59
-->
<template>
  <div class="newsDetail">
    <div class="n_con">
      <div class="n_c_title font-size-32 font-weight-500 font-color-black-1D1D1F">
        {{ detailInfo.messageTitle }}
      </div>
      <div class="n_c_time font-size-18 font-weight-300">{{ detailInfo.updateTime }} 发布</div>
      <p class="n_c_con ql-editor quill font-size-18 font-weight-400" v-html="detailInfo.messageContent"> </p>
    </div>
  </div>
</template>

<script>
import {clientMessageSelectByIdApi} from "@/api/api"
export default {
  name: 'newDetail',
  components: {},
  data() {
    return {
      messageId:null,
      detailInfo:{}
    }
  },
  computed: {},
  created() {
    this.messageId = this.$route.query.id
    this.getInfo()
  },
  mounted() {},
  methods: {
    getInfo(){
      clientMessageSelectByIdApi(this.messageId).then(res => {
        if(res.code === 200){
          this.detailInfo = res.data
          
          // 富文本样式
          if(this.detailInfo.messageContent){
            this.detailInfo.messageContent = this.replaceWhite(this.detailInfo.messageContent)
            console.log("++++decodeURIComponent()",decodeURIComponent(this.detailInfo.messageContent))
          }
        }
      })
    },
    replaceWhite(html) { // 处理富文本默认图片，视频大小
			let newContent = html.replace(/<video[^>]*>/gi, function(match, capture) {
				match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
				match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
				match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
				return match;
			}).replace(/<img[^>]*>/gi, function(match, capture) {
				match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
				match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
				match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
				return match;
			});
			newContent = newContent.replace(/style="[^"]+"/gi, function(match, capture) {
				match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi,
					'max-width:100%;');
				return match;
			});
			newContent = newContent.replace(/<br[^>]*\/>/gi, '');
			newContent = newContent.replace(/\<iframe/gi,
				'<iframe style="width:100%;height:300px;display:inline-block;margin:10rpx auto;"').replace(/\<img/gi,
				'<img style="width:100%;height:auto;object-fit:cover;display:inline-block;margin:10rpx auto;"')
			return newContent;
		},
  },

  watch: {
    '$i18n.locale': function() {
      this.getInfo()
    }
  }
}
</script>

<style scoped lang="scss">
.newsDetail{
  min-height: 50vh;
  background-color: rgb(248,248,248);
  padding-top: 40px;
  padding-bottom: 279px;

  .n_con{
    width: 900px;
    margin: auto;
    background-color: #fff;
    min-height: 50vh;
    padding-top: 40px;
    padding-left: 90px;
    padding-right: 90px;
    padding-bottom: 94px;
    .n_c_title{
     line-height: 48px;
    }
    .n_c_time{
      margin-top: 16px;
      color: rgba(29,29,31,0.6);
      padding-bottom: 24px;
      border-bottom: 1px solid  #D8D8D8;
      margin-bottom: 27px;
    }
    .n_c_con{
      color: rgba(29,29,31,0.8);
      line-height: 40px;
      white-space: pre-wrap !important
    }
  }
}
</style>
